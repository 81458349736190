import { Component } from 'react'
import { OperationStart, ShippingByAsnStartInput, ShippingByAsnStartListAndInput, Page } from 'stylewhere/components'
import {
  Router,
  getDataFromSchema,
  ShippingOperationConfig,
  RemoteOperation,
  RfidReader,
  OperationReadingProvider,
  OperationReadingState,
  FormSchemaData,
} from 'stylewhere/shared'
import { ShippingExtensions } from 'stylewhere/extensions'
import { showToastError, isModalError, askUserConfirmation } from 'stylewhere/utils'
import { Shippings, TmrTag, Asn, DecodedItem, ShippingTag } from 'stylewhere/api'
import type { Routes } from 'stylewhere/pages'
import { RouteComponentProps, StaticContext } from 'react-router'
import { T, __ } from 'stylewhere/i18n'
import { ShippingParcelStartParams } from './ShippingParcelStart'
import { PackingOperationConfig } from 'stylewhere/shared/RemoteOperation'

interface Params {
  opCode: string
}

interface State {
  skipAsnSelection?: boolean
  items: DecodedItem<string>[]
}

const USE_BATCH_READING = true

export default class ShippingStart extends Component<
  RouteComponentProps<Params, StaticContext, OperationReadingState>
> {
  submitPath: Routes = '/shipping/:opCode/reading'
  asnParcelPath: Routes = '/shipping/:opCode/asn/:asnId'
  selfPath: Routes = '/shipping/:opCode'
  operation = RemoteOperation.getOperationConfig<ShippingOperationConfig | PackingOperationConfig>(
    Router.getMatchParams(this.props).opCode
  )
  checkingIdentifier = false
  isModal = false

  state: State = {
    items: [],
  }
  locationState = Router.getLocationState(this.props)

  tags: ShippingTag[] = []

  mustSelectAsn() {
    return (
      this.operation &&
      ['INBOUND', 'OUTBOUND'].includes(this.operation.type) &&
      this.operation.shippingMode === 'shipment' &&
      this.operation.startAsnScreen !== 'no' &&
      !this.state.skipAsnSelection
    )
  }

  setSkipAsnSelection = () => {
    this.setState({ skipAsnSelection: false })
  }

  isAsnSelectMode() {
    return ['inputCode', 'asnList', 'asnListAndInputCode'].includes(this.operation?.startAsnScreen)
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    if (this.operation.startWithRfidSearch) {
      if (USE_BATCH_READING) {
        RfidReader.setBatchInterval(this.operation.batchInterval)
        RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
        RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
        RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
        RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
        await OperationReadingProvider.init(
          this.operation,
          this.locationState,
          this.goBack,
          this.setRfidReaderDecode,
          true
        )
      } else {
        RfidReader.initialize()
        RfidReader.onTagReadCallback = this.onTagReadCallback
      }
    }
  }

  goBack = () => {
    if (this.isAsnSelectMode()) {
      this.setSkipAsnSelection()
    } else {
      Router.navigate('/')
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setDecodeFunction(async (epcs) => {
      return epcs.reduce((acc, epc) => {
        acc[epc] = { epc }
        return acc
      }, {})
    })
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, this.getDecodeRequest())
  }

  getDecodeRequest = () => {
    return {
      url: Shippings.batchValidateEndpoint(this.operation),
      payload: {
        operationId: this.operation.id,
      },
    }
  }

  checkPackingUnpack = async (error: Error, parcelCode: string, onUnpackConfirm?: () => Promise<void>) => {
    if (
      error.message === 'UNPACK' &&
      this.operation.type === 'PACKING' &&
      parcelCode &&
      (this.operation as PackingOperationConfig).allowUnpack
    ) {
      if (await askUserConfirmation(__(T.misc.warning), __(T.confirm.unpack), __(T.misc.cancel), __(T.misc.yes))) {
        await Shippings.unpackPacking(this.operation as PackingOperationConfig, parcelCode)
        onUnpackConfirm && (await onUnpackConfirm())
        return true
      } else {
        return true
      }
    } else {
      return false
    }
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    if (this.checkingIdentifier) return
    this.checkingIdentifier = true
    RfidReader.stop()
    RfidReader.clear()
    const tags = Object.keys(itemMapFromReader).map((code) => ({ epc: code }))
    // const firstTag = tags[0]
    this.tags = [...this.tags, ...tags.map((t) => ({ code: t.epc, tid: '' }))]
    setTimeout(() => {
      this.call(itemMapFromReader)
    }, this.operation.shippingReadTimeout)
  }

  call = async (itemMapFromReader) => {
    let parcelCode

    try {
      const parcelByIdentifier = await Shippings.parcelByIdentifier(this.operation, this.tags)
      parcelCode = parcelByIdentifier?.code || ''
      const parcel = await Shippings.startParcel(this.operation, {
        parcelCode,
        attributes: parcelByIdentifier?.attributes || {},
        operationId: this.operation.id,
        asn: parcelByIdentifier?.asn || {},
      })
      const formData: Record<string, unknown> = {}
      const state: Record<string, any> = { formData, parcel, tags: this.tags.map((t) => ({ epc: t.code })) }
      if (this.isAsnSelectMode()) {
        state.formData.parcelCode = parcelByIdentifier?.code
        // formData.attributes = parcelByIdentifier?.attributes
        state.asn = parcelByIdentifier?.asn
      }
      Router.navigate(this.submitPath, { opCode: this.operation.code }, { state })
    } catch (error) {
      if (await this.checkPackingUnpack(error as any, parcelCode, () => this.onDecodedItemCallback(itemMapFromReader)))
        return
      showToastError(error, __(T.error.error), this.isModal)
    }
    this.checkingIdentifier = false
  }

  // @deprecated  - use onDecodedItemCallback
  onTagReadCallback = async (tag: TmrTag) => {
    if (this.checkingIdentifier) return
    this.checkingIdentifier = true
    RfidReader.stop()
    RfidReader.clear()
    let parcelCode
    try {
      const parcelByIdentifier = await Shippings.parcelByIdentifier(this.operation, [{ code: tag.epc }])
      parcelCode = parcelByIdentifier?.code || ''
      const parcel = await Shippings.startParcel(this.operation, {
        parcelCode,
        attributes: parcelByIdentifier?.attributes || {},
        operationId: this.operation.id,
        asn: parcelByIdentifier?.asn || {},
      })
      const formData: Record<string, unknown> = {}
      const state: Record<string, any> = { formData, parcel, tags: [tag] }
      if (this.isAsnSelectMode()) {
        state.formData.parcelCode = parcelByIdentifier?.code
        // formData.attributes = parcelByIdentifier?.attributes
        state.asn = parcelByIdentifier?.asn
      }
      Router.navigate(this.submitPath, { opCode: this.operation.code }, { state })
    } catch (error) {
      if (await this.checkPackingUnpack(error as any, parcelCode, () => this.onTagReadCallback(tag))) return
      showToastError(error, __(T.error.error), this.isModal)
    }
    this.checkingIdentifier = false
  }

  onSubmit = async (formData, operation: ShippingOperationConfig, schema) => {
    let parcelCode
    try {
      //if (operation.hasChecklist) {
      const payloadStartParcel: any = getDataFromSchema(formData, schema)
      parcelCode = payloadStartParcel.parcelCode
      const parcel = await Shippings.startParcel(operation, payloadStartParcel)
      Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData, parcel } })
      //}
      Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData } })
    } catch (err) {
      if (await this.checkPackingUnpack(err as any, parcelCode, () => this.onSubmit(formData, operation, schema)))
        return
      showToastError(err, 'Start Parcel Error', this.isModal)
    }
  }

  navigateToAsn = (asnId) => {
    const nextRouteParams: ShippingParcelStartParams = {
      opCode: this.operation.code,
      asnId,
    }
    Router.navigate(this.asnParcelPath, nextRouteParams)
  }

  onSelectAsnCode = async (selectedAsn: Asn) => {
    try {
      if (selectedAsn && selectedAsn.id) {
        this.navigateToAsn(selectedAsn.id)
      } else {
        throw new Error(__(T.error.submit_asn))
      }
    } catch (err) {
      showToastError(err, 'Start ASN Error', this.isModal)
    }
  }

  onCreateAsn = async (formData: FormSchemaData) => {
    try {
      if (formData && formData.shipmentCode) {
        const newAsn = await Shippings.startAsn(this.operation, formData.shipmentCode, formData.attributes ?? {})
        const asnId = newAsn.id
        if (asnId) {
          this.navigateToAsn(asnId)
        } else {
          throw new Error(__(T.error.submit_asn))
        }
      }
    } catch (err) {
      showToastError(err, 'Start ASN Error', this.isModal)
    }
  }

  onReadParcel = () => {
    this.setState({ skipAsnSelection: true })
  }

  render() {
    if (this.mustSelectAsn()) {
      switch (this.operation.startAsnScreen) {
        case 'inputCode':
          return (
            <Page title={this.operation.description} onBackPress={() => Router.navigate('/')} enableEmulation={false}>
              <ShippingByAsnStartInput operation={this.operation} onCreateAsn={this.onCreateAsn} />
            </Page>
          )
        case 'asnList':
        case 'asnListAndInputCode':
          return (
            <ShippingByAsnStartListAndInput
              operation={this.operation}
              onSelectAsnCode={this.onSelectAsnCode}
              onClickReadParcel={this.onReadParcel}
              onCreateAsn={this.onCreateAsn}
            />
          )
      }
    }

    const onBackPress = this.isAsnSelectMode() ? this.setSkipAsnSelection : undefined
    return (
      <OperationStart
        onBackPress={onBackPress}
        startWithRfidSearch={this.operation.startWithRfidSearch}
        submitPath={this.submitPath}
        extensions={ShippingExtensions}
        onSubmit={this.onSubmit}
        onTagReadCallback={
          !USE_BATCH_READING && this.operation.startWithRfidSearch ? this.onTagReadCallback : undefined
        }
        setOnDecodedItemCallback={
          USE_BATCH_READING && this.operation.startWithRfidSearch ? this.setRfidReaderDecode : undefined
        }
      />
    )
  }
}
